import styled, { css } from 'styled-components';

import { Asset, CurrencyType } from '@libs/types';

// import {
//   DogeCoinIcon,
//   EtheriumIcon,
//   GelIcon,
//   LitecoinIcon,
//   TronCoinIcon,
//   UsdIcon,
//   PepeCoinIcon,
//   BitcoinIcon,
//   ShibaIcon,
//   UsdtIcon,
//   XrpIcon,
//   CardanoIcon,
//   PolkadotIcon,
//   StepnIcon,
//   GreenSatoshiTokenIcon,
// } from '../assets';

// Restyled ICONS
const defaultSizeStyles = css<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

const CurrencyIconImg = styled.img`
  ${defaultSizeStyles}
`;

export type CurrencyIcons = Record<CurrencyType | string, React.ReactElement>;
export const useCurrencyIcons = (
  size = '3.1rem',
  assets?: Asset[]
): CurrencyIcons => {
  if (assets && assets.length > 0) {
    return assets.reduce((acc, curr) => {
      acc[curr.code] = (
        <CurrencyIconImg
          src={curr.iconUrl}
          size={size}
          className="currency_icon"
        />
      );

      return acc;
    }, {} as CurrencyIcons);
  }

  return {};
};
